.horizontal_date_picker {
  padding: 0;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #111924;
  margin-bottom: 0px;
}

.date_scroll {
  display: inline-flex;
  gap: 10px;
  padding: 10px;
}

.date_pill {
  background-color: #1C2037;
  color: white;
  border: 1px solid #43465B;
  border-radius: 12px;
  padding: 7px 13px;
  cursor: pointer;
  min-width: 56px;
  text-align: center;
  width: 68px;
  height: 68px;
}

.date_scroll .date_pill.selected {
  background-color: #43465B !important;
}

.day, .weekday {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.37389737367630005px;
  text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .date_pill {width: 55px; height: 55px;}
  .day, .weekday {font-size: 12px;line-height: normal;}
}