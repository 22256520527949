.rec {
    position: relative;
}
.recbody {
    background-color: #f2f2f2;
    margin-top: 5px !important;
}
.pageTitle {
    margin-left:10px;
}

.recnav {
    /* background-image: url('../assets//images/nav.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height:78px; */
    position: relative;
    color: white;
    margin-bottom: 10px;
}
.recnav:before{
    content: "";
    position: absolute;
    width: calc(100% - 15px);
    left: 0;
    top: 6px;
    bottom: 6px;
    background: var(--primaryBlue);
    border-radius: 0 100px 100px 0;
    box-shadow: 0px 0px 5px -1px #000;
}

.bgBase {
    background-color: #f2f2f2;
}

.__cHolder {
    height: calc(100vh - 120px);
    overflow-y: auto;
}
.mout {
    width:15px;
    height: 15px;
    border: 1px solid #000;
}
.min {
    width:11px;
    height: 11px;
    border: 1px solid #000;
}
.__lagend {
    position: absolute;
    right: 0;
    top: 5px;
}
.badgeLagend {
    min-width: 75px;
    font-size: 16px !important;
    letter-spacing: 1px;
    color: #838383;
}
.mbg:before {
    background-color: var(--secondaryPink);
}
.tbg:before {
    background-color: var(--primaryBlue);
}
.listBg {
    background-image: url('../assets//images/list.png');
    background-size: 100% 100%;
    border: none !important;
    height: 50px;
    background-repeat: no-repeat;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.listsec {
    display: inline-block;
    width: 20%;
    font-size: 16px;
    margin-top:2px;
    color:#838383;
}
.listIndicator {
    width: 30px;
    height: 37.6px;
    display: inline-block;
    position: absolute;
    left: -29px;
    top: 6px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}
.mListName {
    width: 40%;
    color: var(--secondaryPink);
}
.tListName {
    width: 40%;
    color: var(--primaryBlue);
}
.__w12{
    width:12%;
}
.__w88{
    width:88%;
}
.__calanderGrouph {
    margin-left:25px;
}
.__actionLabel{
    border-right:1px solid #f1f1f1;
    padding-right: 0 !important;
    padding-Left: 0 !important;
}
.__dateInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 135px;
    position: relative;
    top: -69px;
}
.__activeImg {
    height:10px;
    width:100%;
}
.__tvactive {
    color:#595959;
}
.__inactive {
    color:#bfbfbf;
}
.__profile {
    width:70px;
}

.loaderHeight{height: 100vh; width: 100vw;}

.loaderImg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
}

@media only screen and (max-width: 991.98px) {
    .__cHolder{
        height: auto;
    }
    .wnew__holder{
        padding-right: 15px !important;
    }
}