.logo {
    width:460px;
    margin-bottom:40px;
}

.wrapper{
    display:flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
}

.main {
    min-width: 500px;
    margin-top: 150px;
}
._searchInput {
    height:38px !important;
    border-radius: 24px !important;
    background-color: transparent !important;
    box-shadow: 3px 0px 20px 0px var(--secondaryPink);
    padding: 0px 50px 0 20px !important;
    font-size: 20px !important;
    border-color: transparent !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    color: var(--primaryBlue) !important;
    font-weight:400;
}

._searchInput:focus {
    box-shadow: 3px 0px 20px 0px var(--secondaryPink) !important;
}

.searchIcon {
    position: absolute;
    padding:0;
    pointer-events: none;
    width: 20px;
    top: 7px;
    right: 20px;
}

._searchInput::placeholder {
    color: var(--primaryBlue) !important;
    opacity: 1; /* Firefox */
    font-weight: lighter;
    font-weight: 100;
  }

  .element::-webkit-input-placeholder {
    font-weight: lighter;
    font-weight: 100;
}
  
  ._searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: var(--primaryBlue) !important;
   font-size: 14px !important;
   font-weight: lighter;
  }
  
  ._searchInput::-ms-input-placeholder { /* Microsoft Edge */
   color: var(--primaryBlue) !important;
   font-size: 14px !important;
   font-weight: lighter;
  }

  .searchDropdown {
    position: absolute !important;
    z-index: 1;
    width: 470px !important;
    max-height: 312px;
    overflow-y: auto;
    box-shadow: 1px 8px 20px 1px var(--secondaryPink);
    border-bottom-right-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
    display:none;
    min-height: 100px;
  }

  .labelBtn {
    border:1px solid var(--primaryBlue) !important;
    color: #fff !important; 
    background-color: var(--primaryBlue) !important;
    border-radius: 0 !important;
    font-size:18px !important;
    margin-right: 15px;
  }
  
  .listImg {
      width:30px;
      height: 30px;
      border-radius: 50%;
  }

  .listlabel {
      width: 50%;
      color:var(--primaryBlue);
      padding: 0px 10px;
      text-align: left;
      font-weight: 400;
      letter-spacing: 0.5px;
  }

  .listInfo, .listGroup {
    color: #3d3d3d; 
    width:20%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .listGroup {width:20% !important;}
  .baseLine {
      display:none;
  }

  .Loading {
    top: 10px;
    right: 10px;
    position: absolute;
    width:30px;
    height:30px;
    color:#ccc;
  }

  .imgWrap{
      width:10%;
  }
  .cwhite, .cwhite:hover {
      color:#fff;
  }
  


