.trending {
    
    .navArrowRight{
        transform: rotate(180deg);
    }
    .navArrowLeft{
        margin-left: 50px;
    }
    .setTwo{
        display: none;
    }
    .setTwoDisplay {
        display: block !important;
    }
}

.setOneDisplay{
    display: none;
}

@media only screen and (max-width: 991.98px) {
    .setOneDisplay{
        display: block;
    }
    .fullHeightTrend{
        min-height: calc(100vh - 44px);
        padding-top: 100px;
        >div{
            min-height: auto !important;
        }
    }    
    .trending {        
        .setTwo{
            display: block;
            margin-top: 15px;
        }
        .__filterWrapper{
            display: inline-flex;
            .filterBg{
                display:none;
            }
        }
        .rec__header {
            border-bottom: 0;
            
        }
        ._recInput {
            height: auto !important;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        .rec__search{
            top: 58px;
        }
        .__serachIcon {
            height: 30px;
        }
        .fixHeader{
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            padding-left: 15px;
            padding-right: 15px;
            z-index: 99;
            &:before {
                content: "";position: fixed;left: 0;right: 0;top: 0;height: auto;background: #f2f2f2;z-index: -1;
            }
        }
        .fixTitle{
            position: fixed;
            top: 121px;
            left: 15px;
            right: 17px;
            z-index: 99;
        }
        .stickyHeader{
            position: fixed;
            top: 121px;
            left: 15px;
            right: 17px;
            z-index: 99;
        }
        .fixContent{
            margin-top: 70px;
        }
        .setTwo{
            .fixContent{
                margin-top: 0;
            }
            .fixHeader,.fixTitle{
                position: static;
            }
        }
    }

    .stickyHeaderTrending .fixTitle {
        position: static;
    }
    
    .stickyHeaderTrending .fixContent {
        margin-top: 0;
    }
    
    .stickyHeaderPopular .fixTitle {
        position: fixed !important;
    }
    
    .stickyHeaderPopular .fixContent {
            margin-top: 30px !important;
            margin-bottom: 70px;
        }
    
    
}