.Privacy{
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    letter-spacing: .01em;
    line-height: 1.1em;
    text-transform: none;
    color: #000;
}
.baseTitle{
   justify-content: center;  
   font-size: 35px;
   line-height: 1em;
   color: #f4911f;
   margin-top: 40px;
}
.subTitle
{
    font-weight: 600;
    font-size: 25px;
    letter-spacing: .02em;
    margin:20px 0;
}
.ul{padding-left: 3rem;}
