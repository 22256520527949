body{
    background-color: #f2f2f2 !important;
}
.trending {
    background: #1c2037;
    min-height: 100vh;
}
.fullHeight{
    height: calc(100vh - 89px);
    min-height: 635px;
    background: #1C2037;
}
.pageTitleSection {
    list-style: none;
    padding: 0 20px;
    margin: 10px 0 20px -15px;
    background: var(--primaryBlue);
    color: #fff;
    border-radius: 0 100px 100px 0;
    display: flex;
    flex-direction: row;
    height: 58px;
}

.pageTitleSection>li{
    width: calc(100vw - 100px);
}

.title {
    margin: 0;
    padding: 4px 0 0 0;
    font-size: 2.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    display: inline-block;
}

.imgHolder {
    min-width: 70px;
    max-width: 70px;
    margin-right: 10px;
    position: relative;
}
.proPic{
    position: absolute;
    width: 70px;
    left: 0;
    right: 0;
    top: -10px;
    bottom: -10px;
    margin: auto;
}
.whiteBox {
    /* background: #fff; */
    padding: 0 15px 0 15px;
    /* box-shadow: 0px 0px 6px -3px #000; */
}

.time {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 10px auto 16px;
    font-size: 1.4em;
    justify-content: space-between;
}

.mostPopularList {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    overflow-y: auto;
    height: calc(100vh - 225px);
}

.mostPopularList>li{
    margin-bottom: 15px;
}

.mostPopularSet {
    list-style: none;
    padding: 12px 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    /* box-shadow: 0px 0px 7px -4px #000; */
    justify-content: space-between;
    border-radius: 12px;
    overflow: hidden;
    align-items: center;
    position: relative;
    background: linear-gradient(258.88deg, #25293F 0%, #3D425F 100%);
    border-image-source: linear-gradient(255.02deg, #1C2037 56.02%, #111924 100%);
}

.metaList {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-flex;
    width: 60%;
    text-align: left;
}

.metaList span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.countTag {
    width: 54px;
    margin-right: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7E829F;
    font-size: 1.4em;
    height: 100%;
    max-width: 54px;
}
.countTag span{
    position: relative;
    z-index: 1;
    top:-10px;
    font-size: 36px;
}

.listTitle {
    display: inline-flex;
    width: 40%;
    height: 100%;
    padding-right: 15px;
    color: var(--primaryBlue);
}

.mostPopularSet>li {
    width: 100%;
}

.listTitle h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.37px;
    color:#fff;
    margin-bottom: 14px;
}

.metaList>li {
    padding-right: 15px;
    max-width: calc(100% / 3);
    width: 100%;
}

.countTag:before {content: "";background: #111924;position: absolute;top: 0;bottom: 0;left: 0;width: 54px;z-index: 0;border-top-left-radius: 12px;border-bottom-left-radius: 12px;}
.mostPopularSet>li:nth-child(2) {
    width: 100%;
}
.mostPopularSet>li:nth-child(3) {
    width: 50px;
}



.statusIcon button{    
    background-color: transparent;
    border: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 17px;
    height: 22px;
    display: inherit;
    margin: auto;
    cursor: pointer;
    outline: none;
}
.up, .down, .neutral{
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 0 !important;
}
.up{
    background-image: url(../assets/images/up.png);
}
.down{
    background-image: url(../assets/images/down.png);
}
.neutral{
    background-image: url(../assets/images/neutral.png);
}
.navArrow{
    background-image: url(../assets/images/navArrow.jpg);
    width: 15px;
    height: 20px;
    background-color: transparent;
    border: 0;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    cursor: pointer;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 0 !important;
}
.navArrow:hover, .navArrow:active, .navArrow:focus{
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 0 !important;
}
.toggleBtn button{    
    background-color: transparent;
    border: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url(../assets/images/toggleNo.png);
    width: 22px;
    height: 22px;
    display: inherit;
    margin:0 5px;
    cursor: pointer;
    outline: none;
    box-shadow: none;
}

.toggleBtn button:hover, .toggleBtn button:active, .toggleBtn button:focus{
    outline: none;
    box-shadow: none !important;
    border: 0 !important;
    background-color: transparent !important;
}
.toggleBtn .yes{
    background-image: url(../assets/images/toggleYes.png);
}

.toggleBtn{
    margin:15px auto;
    display: inline-block;
}
.movieClass .listTitle h3{
color: var(--secondaryPink);
}
.movieClass .countTag:before{
    background-color: var(--secondaryPink);
}
.timestamp{display: none;}
.plyImg{width:8px;}

@media only screen and (max-width: 991.98px) {
    .title {
        font-size: 1.5em;
        padding: 7px 0 0 0;
        line-height: normal;
    }
    .timestamp{
        font-size: .95em;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
        margin-top: -10px;
    }
    .pageTitleSection{
        margin: 6px -14px 12px -30px;
        padding-top: 0;
        padding-bottom: 0;
        height: 60px;
    }
    .time,.toggleBtn{display: none;}
    .whiteBox{
        max-width: unset !important;
        background: transparent;
        box-shadow: none;
        padding: 0 4px !important;
    }
    .fullHeight{
        /* min-height: calc(100vh - 44px); */
        height: auto;
        min-height: auto;
    }
    .listTitle, .metaList{
        width: 100%;
    }
    .listTitle h3{
        padding: 4px 0 0;
        font-size: 1.6em;
    }
    .mostPopularList>li{
        margin-bottom: 7px;
    }
    .mostPopularSet{
        border-radius: 12px;
        /* box-shadow: 0px 0px 4px -1px #000; */
    }
    .metaList span{
        font-size: 1.2em;
        line-height: normal;
        padding-bottom: 5px;
    }
    .countTag span{
        font-size: 1.4em;
    }    
}


/* height only */
@media only screen and (max-height: 860px) and (min-width: 992px){
    .mostPopularList>li{
        margin-bottom: calc(10vh - 64px);
    }
    .listTitle h3{
        padding: 0;
    }
    .listTitle h3,.metaList span{
        line-height:normal;
    }
}
@media only screen and (max-height: 700px) and (min-width: 992px){
    .mostPopularList>li{
        margin-bottom: 5px;
    }
    .whiteBox{
        padding-bottom: 10px;
    }
}
