//common
* {
    margin: 0;
    padding: 0;
    outline: none;
}

// fonts
@font-face { font-family: Base-font; src: url('../assets/fonts/UniSansRegular.ttf'); } 
@font-face { font-family: Ragular-font; src: url('../assets/fonts/ReportRegular.ttf'); } 
@font-face { font-family: Logo-font; src: url('../assets/fonts/PrehistoricCaveman.ttf');}
@font-face { font-family: Poppins-font; src: url('../assets/fonts/PoppinsRegular.ttf');}

//base
body {
    font-family: Ragular-font  !important;
}

a{
    color: #2227f0;
    &:hover{
        text-decoration:none;
    }
}
/*----width px------------------*/
$class-slug: wp;
@for $i from 1 through 1000 {
  .#{$class-slug}-#{$i}{
    width: 0px + $i !important
  }
}
/*----fontsize------------------*/
$class-slug: fsize;
@for $i from 1 through 100 {
  .#{$class-slug}-#{$i}{
    font-size: 0px + $i !important;
    line-height: 0px + $i !important
  }
}

/* Webkit */
[placeholder]:focus::-webkit-input-placeholder { opacity: 0 !important; }
input:focus::-webkit-input-placeholder { color:transparent !important; }
/* Firefox < 19 */
[placeholder]:focus:-moz-placeholder { opacity: 0 !important; }
input:focus:-moz-placeholder { color:transparent !important; }
/* Firefox > 19 */
[placeholder]:focus::-moz-placeholder { opacity: 0 !important; }
/* Internet Explorer 10 */
[placeholder]:focus:-ms-input-placeholder { opacity: 0  !important; }

:root{
  --primaryBlue: #1a1ab4;
  --primaryBlueDark: #0d0f5a;     
  --primaryBlueLight: #7677d2; 
  --secondaryPink: #c559cd;  
  --headerBlue: #111924;
}

.details-view, .hDatePicker {
  font-family: Poppins-font  !important;
}
.details-view{padding: 18px;}

.spinner-border {
  border: 1px solid !important;
  border-right-color: transparent !important;
  padding: 10px;
}
.mLogo{display: none;}

// calendar ui
.mLink{color: #3D425F; font-size: 20px;line-height: 30px;}
.mLink.active{color:#7E829F;text-decoration: underline;}
.mLink:hover{color:#7E829F;}
.detail-network .actionBox{gap: 6px;}
.actionBox .actionBtn {cursor: pointer; font-weight: 300; padding: 6px 12px 6px 12px;border-radius: 6px; border: 1px 0px 0px 0px;border: 1px solid #7E829F;line-height: normal;color:#7E829F;}
.trailerBtn{background:#7E829F;}
.trailerBtn .info{color:#2F324C;}
.customDatePicker{font-family: Poppins-font  !important;}
.customDatePicker .DayPicker{background: #1C2037;border: 1px solid #43465B !important;border-radius: 12px; margin-top:24px;color:#7E829F;}
.customDatePicker .wnew__customCalendar .DayPicker-Weekday{color:#7E829F !important;}
.customDatePicker .wnew__customCalendar .DayPicker-NavButton--prev {background-image: url('../../src/assets/images/back-arrow.svg');}
.customDatePicker .wnew__customCalendar .DayPicker-NavButton--next {background-image: url('../../src/assets/images/next-arrow.svg');}
.customDatePicker .wnew__customCalendar .DayPicker-Day {background-image: url('../../src/assets/images/day.png');}
.customDatePicker .wnew__customCalendar .DayPicker-Day--today{background-image: url('../../src/assets/images/today.png');}
.customDatePicker .wnew__customCalendar .DayPicker-Day--outside{background-image: none; pointer-events: none;}
.customDatePicker .wnew__customCalendar .DayPicker-Day--selected{background-image: url('../../src/assets/images/select.png');}
.customDatePicker .wnew__customCalendar .DayPicker-Day--disabled{background-image: url('../../src/assets/images/disabled.png'); color: #707070;}
.customDatePicker .DayPicker-Caption{text-align: center;}
.mMenuIcon{display:none;}
.textLink{text-decoration: underline;}

// Trending
.displayTxt li{color: #7E829F;font-size: 20px;font-weight: 500;line-height: 30px;}
.displayTxt .titleTxt{color: #fff;font-size: 36px; line-height: 54px;letter-spacing: -0.5px;}
.trendActionBox{gap: 6px;}
.trendActionBox .actionBtn {cursor: pointer; font-weight: 300; padding: 6px;border-radius: 6px; border: 1px 0px 0px 0px;border: 1px solid #7E829F;line-height: normal;color:#7E829F;}
.desktop.desktopView{display: none;}

.feedMain {background: #1C2037;height:100dvh; font-family: Poppins-font  !important;}
.feedTitle{color: #fff;}
.feedMain .form-label{color: #7E829F;}
.feedSubmit{background: #7E829F !important;border-radius: 6px !important;color:#1C2037 !important; border: 1px solid #7E829F !important;}
.feedMain input,.feedMain textarea{
  min-height: 50px;
  color: #fff !important;
  border: 1px solid #43465B !important;
  background: linear-gradient(258.88deg, #25293F 0%, #3D425F 100%);
  border-image-source: linear-gradient(247.15deg, #1C2037 46.56%, #7E829F 100%);}


@media only screen and (max-width: 991.98px) {
  .mainLogo{display: inline-block;}
  .dLogo {display:none;}
  .mMenu.mHide{display:none !important;}
  .mMenu.mOpen{display: flex;}
  .mLogo{display: block;}
  .mMenuIcon{display: block;}
  .mMenuIcon .hamburger-menu{background: #272B41;}
  .mMenu{flex-direction: column;gap: 8px;background: #272B41;position: absolute;top: 45px;padding: 10px;border-radius: 6px;border-top-right-radius: 0; box-shadow: -4px 4px 12px 0px #00000040;min-width: 124px;}
  .cCard{margin-bottom: 10px !important;}
  .details-view{padding: 0 10px;}
  .mobile.desktopView .titleTxt{display: none;}
  .mobile.mobileView .displayTxt li {display: none;} 
  .mobile.typeBody .whiteBox{padding: 0 !important;}
  .mobile.typeBody .mainList{padding: 0 10px; height:calc(100vh - 185px);}
  .mobile.typeBody .mobile.mobileView .displayTxt {margin:0;padding: 0 15px 10px;background: #111924;border-bottom: 1px solid #000;}
  .mobile.typeBody .mobile.desktopView .displayTxt li{display: flex;flex-direction: column;}
  .mobile.typeBody .mobile.desktopView .displayTxt li span:nth-child(1){font-size: 16px;}
  .mobile.typeBody .mobile.desktopView .displayTxt li span:nth-child(2){font-size: 24px;}
  .feedMhead{height: 72px !important;}
  .feedFooter{max-width: 100%;}
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .actionBox .actionBtn{font-size: 12px;padding: 5px;min-width: max-content;}
  .detail-engagement .detailScore, .entry p {font-size: 13px;text-align: right;}
  .cCard{margin-bottom: 1.5rem !important;}
  .actionBox{flex-wrap: wrap;}
}

@media only screen and (max-width: 767px) {
  .feedMain{height:auto !important;}
  .feedTitle {line-height: 32px;font-size: 26px;}
}