.about{
    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    letter-spacing: .01em;
    line-height: 1.1em;
    text-transform: none;
    color: #000;
}
.baseTitle{
   justify-content: center;  
   font-size: 35px;
   line-height: 1em;
   color: #f4911f;
   margin-bottom: 30px;
}
.subTitle
{
    font-weight: 600;
    font-size: 25px;
    letter-spacing: .02em;
    margin:20px 0;
}
.headerText{color: var(--secondaryPink); font-size: 44px;font-weight: 600; margin-bottom: 30px;}
.ContentArea{flex-direction: column;}
.btnRedirect{    
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: .15em;
    color: #fff;
    background-color: var(--primaryBlue);
    border-color: var(--primaryBlue);
    padding: 13px 26px;
    border-radius: 100px;
    margin-top: 30px !important;
    border: 1px solid;
}
.btnRedirect:hover{color: var(--primaryBlue);
    background-color: #fff;
    border-color: var(--primaryBlue); 
}
.hrClr{color: var(--secondaryPink);background-color: var(--secondaryPink);}
.SectionBorder{border-bottom: 1px solid; padding-bottom: 30px; width: 70%;}
.feedBackForm{font-size: 20px;}
.btnCustom{color: #fff !important; background-color: var(--primaryBlue) !important; border-color: var(--primaryBlue) !important; border-radius: 0 !important; padding: .5rem 2rem !important; text-transform: uppercase; letter-spacing: 1.2px;}
