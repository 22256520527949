.hamburger-menu {
    position: relative;
  }
  
  .menu-toggle {
    font-size: 24px;
    cursor: pointer;
  }
  
  .menu-img {
    width: 40px;
  }

  .menu-nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #0d0f5a;
    padding: 10px;
    border-radius: 4px;
    z-index: 1000 !important;
  }
  
  .menu-nav a {
    color: #fff;
    text-decoration: none;
    margin: 8px 0;
    z-index: 1001 !important;
  }
  
  .menu-nav a:hover {
    color: #ddd;
  }
  