.wnew {
    &__calanderGrouph {
        &:last-child {
            margin-bottom: 50px;
        }
    }
    &__customCalendar {
        .DayPicker-Month {
            width:100%;
            margin-left: 0em;
        }
        .DayPicker-Day {
            background-image: url('../assets/images/calendar.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &:hover {
                background-color: transparent !important;
            }
        }
        .DayPicker-NavButton--prev {
            background-image: url('../assets/images/back.svg');
            width:15px;
            height:15px;
        }
        .DayPicker-NavButton--next {
            background-image: url('../assets/images/next.svg');
            width:15px;
            height:15px;
        }
        .DayPicker-Day--outside {
            background-image: none; 
            pointer-events: none;
        }
        .DayPicker-Day--today {
            background-image: url('../assets/images/calendarActive.png');
            color: white;
            font-weight: normal;
            &:hover {
                background-color: transparent !important;
            }
        }
        .DayPicker-Day--selected {
            background-image: url('../assets/images/calendarSelected.png');
            background-color: transparent !important;
            color: var(--primaryBlue);
            &:hover {
                background-color: transparent ;
            }
        }
        .DayPicker-Weekday {
            color: #2d2e2e !important;
            abbr{
                cursor: auto;
            }
            
        }
        abbr {
            &:hover {
                cursor: auto;
            }
        }
    }
    &__lweek{
        font-size: 18px;
    }
    &__lday{
        font-size: 21px;
    }
    
.rowSet {
    border-radius: 25px;
    box-shadow: 0px 0px 8px -3px #000;
    margin: 15px auto;
    position: relative;
    padding-left: 40px;
    overflow: hidden;
    display: flex;
    min-height: 40px;
    align-items: center;
    font-size: 1.1em;
    &:before {content: "";position: absolute;top: 0;bottom: 0;left: 0;width: 30px;}
    .titleSet {
        display: grid;
        width: 50%;    
        color: var(--primaryBlue);
        padding-right: 20px;
        span{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-wrap: wrap;
            width: 100%;
            display: block;
            font-size: 20px;
            color: var(--primaryBlue);
        }
        }
        .flags {
            list-style: none;
            padding: 0;
            margin: 0;
            display: block;
            justify-content: flex-start;
            width: 50%;
            color: #838383;
            >li {
                width: 33%;
                padding: 0 20px 0 0;
                display: inline-grid;
                font-size: 20px;
                >span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #838383;
                    }
                }
            }
    }
    .__filterWrapper{
        .filterBg{
            display:none;
        }
    } 
    .calanderActive {
        td {
            border-top: 1px solid #7677d2;
            span{
                color: #7677d2;
            }
        }
    } 
    .calandarCurrent {
        td {
            border-top: 1px solid var(--primaryBlue);
            span{
                color: var(--primaryBlue);
            }            
        }
    }
    .login {
        margin-left: 70% !important;
        cursor: pointer;
    }
}


@media only screen and (max-width: 991.98px) {
    .wnew{
        .fullHeight {
            min-height: calc(100vh - 159px);
            height: auto;
            >*{
                height: auto !important;
                margin-top: 195px;
            }
        }
        .pageTitle{
            font-size: 1.45em;
        }
        .rowSet{
            flex-direction: column;
            align-items: baseline;
            border-radius: 15px;
            .titleSet {
                display: grid;
                width: 100%;
                line-height: normal;
                padding-top: 5px;
                    span{
                        width: calc(100vw - 180px);
                    }
                    
                }
                .flags {
                    width: 100%;
                    >li{
                        padding: 0 5px 0 0;
                        font-size: 16px;
                        }
                }
            }
            &__loaderSection {
                width:100%;
                img {
                   width:100%; 
                }
            }
            &__holder {
                padding-right: 5px !important;
                padding-left: 5px !important;
            }
            &__mremovep {
                padding-left: 0;
                padding-Right: 0;
            }

            &__dateInfo {
                width: 100px;
            }
            &__lweek {
                font-size: 14px;
                margin-top: 5px;
            }
            &__lday {
                font-size: 16px;
            }
            .rec__search {
                top:58px;
                position: fixed !important;
                z-index: 9;
            }
            .rec__header {
                text-align: left;
                position: fixed;
                z-index: 9;
                top: 0;
                .__hLogo {
                    margin-top: 15px;
                }
            }
            .wnew__body{
                position: fixed;
                z-index: 9;
                top: 110px !important;
                width: 100%;
            }
            .wnew__bgBase{
                margin-top: 195px;
            }
    } 
    .cnew {
        .cnav {display: none !important;}
        .rec__header{height: 64px !important;position: relative !important; text-align: center !important;}
        .hamburger-menu{margin-top:10px;}
        .wnew__bgBase{margin-top: 0 !important;}
        .nCalendarList{height: calc(100vh - 194px);}
        .wnew__holder{padding-right: 0 !important;}
        .rec__footer{margin-top: 0;}
        .wnew__bgBase .wnew__holder {padding-right: 5px !important;}
    }

}