.recHeader {
  background: #111924;
  height: 72px;
}
.logo {
  width: 200px;
  margin-top: 10px;
}

.recSearch {
  position: absolute !important;
  right: 0;
  width: 300px !important;
  top: 10px;
}

.recInput {
  height:25px !important;
  border-radius: 15px !important;
  box-shadow: 0px 1px 15px var(--primaryBlueDark);
  color: var(--primaryBlueDark) !important;
  border: 1px var(--primaryBlueDark) solid;
  padding-right: 30px !important;
}

.recInput:focus{
  box-shadow: 0px 1px 15px var(--primaryBlueDark) !important;
  border-color:var(--primaryBlueDark) !important;
}

.recInput::placeholder {
  color: var(--primaryBlueDark) !important;
  opacity: 1; /* Firefox */
}
  
.recInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--primaryBlueDark) !important;
}
  
.recInput::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--primaryBlueDark) !important;
}

.searchIcon {
  position: absolute;
  padding: 5px;
  pointer-events: none;
  right: 5px;
  width: auto;
  height: 25px;
}

.baseLine {
  display:none;
}

.searchDropdown {
  position: absolute !important;
  z-index: 1;
  width: 270px !important;
  max-height: 330px;
  overflow-y: auto;
  box-shadow: 1px 8px 20px 1px rgba(185, 69, 195, 0.54);
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  display:none;
  min-height:90px;
}

.imgWrap{
  width:10%;
}

.listlabel {
  width: 65%;
  color:var(--primaryBlueDark) ;
  padding: 0px 10px;
  text-align: left;
  font-size: 1rem;
  height:27px;
}

.listInfo {
  color:#3d3d3d;
  width:25%;
  font-size: 1rem
}

.listImg {
  width:30px;
  height: 30px;
  border-radius: 50%;
}

.filterToggle {
  display: flex;
    width: 50px;
    text-align: right;
    height: 47px;
    background: var(--primaryBlueDark);
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
}

.filterIcon {
  width:30px;
}

.Loading {
  top: -4px;
  right: 0px;
  position: absolute;
  width: 30px;
  height: 30px;
  color: #ccc;
}

.filterWrapper {
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--primaryBlueDark);
  padding-left: 15px;
  margin-left: 15px;
  display:none;
}

.headerHamburger {
  background: #0d0f5a;
}