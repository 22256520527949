.recfooter {
  position: fixed !important;
  bottom: 0;
  background-color: var(--headerBlue);
  text-align: right;
  left:0;
  z-index: 9;
}

.fbtn, .fHbtn {
  padding: 0 !important;
  font-size: 12px !important;
  margin-right:15px;
  color:#3D425F !important;
  line-height: 1 !important;
  text-decoration: none;
  cursor: pointer;
}

.recHfooter {
  position: fixed !important;
  bottom: 0;
  background-color: #bfbfbf;
  text-align: right;
}

.recfooter a {
  color: #3D425F !important;
}