.formActive {
    position: relative;
 ._searchInput {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
 }
 .baseLine {
     display: block;
     width: 95%;
     position: absolute;
     border-bottom: 1px solid var(--primaryBlue) !important;
     bottom: 0;
 }
 .searchDropdown {
     display:block !important;
     width: 100% !important;
     .list-group-item { border-color: #bfbfbf;
         &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
         }
         &:hover {
             background-color: var(--primaryBlue) !important;
            .listlabel,.listInfo,.listGroup {color: #fff !important;
            cursor:pointer;}
         }
    }
 }
}

._searchInput{
    &:focus {
        .baseLine {
            display:block;
        }
    }
}

.rec__fHbtn{
    &:hover {
        text-decoration: none;
    }
}
.search-wrapper {

    >.flex-column{
        width: 600px;
    }
}


@media only screen and (max-width: 991.98px) {
    .search-wrapper {
        >.flex-column{
            width: calc(100vw - 150px);
            .searchMain {
                padding: 0;
                .form-control._searchInput{
                    width: 100% !important;
                    padding-right: 30px !important;
                }
            }
        }

        .rec__footer{
            position: fixed !important;
        }
        .logo {width: 280px;}
        .searchMain {
            min-width: 100%;
            .baseLine {
                width:95%;
            }
            ._searchInput {
                font-size: 14px !important;
                padding: .375rem .5rem !important;
            }
            .searchIcon {
                width: 15px;
                top: 12px;
                right: 12px;
            }
        }
        
        .searchDropdown {
            width: 100%;
            height:200px;
            .list-group-item {
                font-size: 16px;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .labelGrouph {
            button {
                font-size: 12px !important;
                margin-right: 5px;
            }
        }
    }
    ._searchInput::placeholder {
        font-size: 13px;
      }
      ._searchInput{
        height: 40px !important;
      }
}


@media only screen and (max-width: 767.98px) {
    .search-wrapper {
        >.flex-column{
            width: calc(100vw - 50px);
        }
    }
}