.rec {
    position: relative;
}
.recbody {
    background-color: #f2f2f2;
    margin-top: 5px !important;
}

.recnav {
    /* background-image: url('../assets//images//nav.png');
    background-repeat: no-repeat;
    background-size: 100% 100%; */
    position: relative;
}
.recnav:before{
    content: "";
    position: absolute;
    width: calc(100% - 15px);
    left: 0;
    top: 6px;
    bottom: 6px;
    background: var(--primaryBlue);
    border-radius: 0 100px 100px 0;
    box-shadow: 0px 0px 5px -1px #000;
}

.__profile {
    width: 70px;
    border: 3px solid var(--primaryBlue);
    border-radius: 50%;
}
.subText {
    line-height: 1;
}
.navlabel {
    text-align: right;
}

.hbtn {
    color: #fff !important;
    font-size: 26px !important;
    margin-right: 70px;
    cursor: initial !important;
}

.dImage {
    width: 30px;
    height:30px;
    border-radius: 50%;
}

.tLink {
    width:28px;
    height:28px;
    cursor: pointer;
}

.infoText, .infoText a {
    color: var(--primaryBlue);
}
.arrowDown {
    position: absolute;
    width: 100%;
    bottom: -36px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actionDown {
    width: 35px;
    height: 35px;
    padding: 10px;
    border-radius: 50%;
    background-color: var(--primaryBlueDark);
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.actionDown:hover{
    background-color: #fff;
    border-color: var(--primaryBlueDark);
}

.dIcon {
    width:15px;
    height:15px;
    cursor: pointer;
}
.rec__holder {
    height: calc(100vh - 150px);
    overflow-y: auto;
    padding-right: 0px !important;
}

.bgBase {
background-color: #f2f2f2;
}

.subfilter {
    margin-bottom: 0;
    color:#838383;
}

.holderText {
    color: #666666;
    font-size: 18px;
}
.loaderHeight{height: 100vh; width: 100vw;}
.loaderImg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
}

@media only screen and (max-width: 991.98px) {
    .recnav .subText {
        font-size: 14px !important;
    }
    .recnav:before{
        width:calc(100% - 5px);
        top: 0;
        bottom: 0;
    }
}
