* {
    box-sizing: border-box;
  }

  .calendar-page {
    background-color: #1a1a2e;
    color: #fff;
    padding: 10px;
  }
  
  .days-scroll {
    display: flex;
    overflow-x: auto;
    margin: 10px 0;
    padding: 10px 0;
    background-color: #333;
  }
  
  .day-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1em;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    width: 60px;
  }
  
  .day-button.selected {
    background-color: #555;
    border-radius: 5px;
  }
  
  .calendar-list {
    overflow-y: auto;
    /* max-height: 500px; */
    background: #1C2037;
  }
  
  .calendar-day {
    margin-bottom: 20px;
    background: #1C2037;
  }
  
  .calendar-day h2 {
    text-transform: capitalize;
    color: #aaa;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .entry {
    background-color: #222;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 12px;
    border: 1px;
    background: linear-gradient(258.88deg, #25293F 0%, #3D425F 100%);
    border-image-source: linear-gradient(247.15deg, #1C2037 46.56%, #7E829F 100%);
}
  
  .entry h3 {
    margin: 0;
    font-size: 1em;
  }
  
  .entry p {
    margin: 0;
    font-size: 0.9em;
    color: #888;
  }
  
  .detail-month{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #7E829F;   
  }
  
  .detail-day{
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #7E829F; 
    margin-bottom: 18px;
  }

  .detail-name {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.37389737367630005px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 12px;
  }
  .detail-name a{color: #fff !important;}
  
  .details-row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .detail-network {
    font-size: 16px;
    font-weight: 300;
    line-height: 35.89px;
    letter-spacing: -0.37389737367630005px;
    text-align: left;
    color: #AEAFB8;
  }

  .playBtn{
    width: 40px;
    height: 30px;
    margin-left: 10px;
  }

  .shadow-sm, .details-view {
    background: #1C2037;
  }

  .card {
    border: 0px !important;
  }
.detail-engagement {color: #7E829F;}
 .detail-engagement .detailScore {
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.37389737367630005px;
  text-align: right;
}

@media only screen and (max-width: 991.98px) {
  .detail-month {font-size: 16px;}
  .detail-day{font-size:30px;}
  .detail-name {font-size: 20px;}
}
