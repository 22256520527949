.rec {
    .toggleArrow {
        span{
            &:after{
                content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    visibility: hidden;
                    background-image: url(../assets/images/downNewHover.png);
            }
            &:hover {                        
                &:after{
                    visibility: visible;
                }
            }
        }
    }
    .watchnxt-enter-done {
        .toggleArrow{
            span{
                &:after {
                    background-image: url(../assets/images/upNewHover.png);                        
                }
            }
        }
    }
    .watchnxt-exit-done {
        .toggleArrow {
            span{
                &:after {                        
                    background-image: url(../assets/images/downNewHover.png);
                }               
            }
        }
    }
    background-color: #f2f2f2;
    &__fbtn {
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            text-decoration: none;
        }
    }
    &__hbtn {
        &:last-child {
            margin-right: 50px;
        }
        &:hover {
            text-decoration: none;
        }
    }
    &__table{
        th {
            color:#bfbfbf;
            letter-spacing: 1px;
            border-bottom: 0px solid #dee2e6 !important;
            border-top:1px solid transparent;
            font-weight: 100;
            font-size: 19px;
            white-space: nowrap;
            &:nth-child(1){
                width:35%;
                padding-left: 32px;
            }
            &:nth-child(2){
                width:15%;
            }
            &:nth-child(3){
                width:15%;
            }
            &:nth-child(4){
                width:15%;
            }
            &:nth-child(5){
                width:15%;
            }
            &:nth-child(6){
                width:5%;
            }
        }
        td {
            color:#838383;
            font-size: 19px;
            white-space: nowrap;
            &:nth-child(1){
                padding-left: 0px;
                .tableText {
                    width:300px;
                }
            }
        }
    }
    &__filter {
        .list-group {
            color:#838383;
            font-size:19px;
        }
    }
    &__tableHeight {
        height:376px;
        overflow: hidden;
    }
    &__tableFit {
        height:max-content !important;
        overflow: auto;
    }
    .hideTVPlayButton {
        visibility: hidden;
    }
}


.searchActive {
    position: relative;
 ._recInput {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
 }
    .baseLine {
        display: block;
        width: 95%;
        position: absolute;
        border-bottom: 1px solid var(--primaryBlue) !important;
        bottom: 0;
    }
    .searchDropdown {
        display:block !important;
        .list-group-item {
            &:first-child {
               border-top-left-radius: 0;
               border-top-right-radius: 0;
            }
            &:hover {
                background-color: var(--primaryBlue) !important;
               .listlabel,.listInfo,.listGroup {color: #fff !important;
                cursor:pointer;
            }
            }
       }
    }
}

.titleLink {
    pointer-events: none;
    cursor: auto;
}

@media only screen and (max-width: 1050px) {
    .rec{
        &__table{
            th {
                &:nth-child(1){
                    width: 30%;
                }
            }
            td{
                &:nth-child(1){
                    .tableText {
                        width: calc(100vw - 800px);
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 991.98px) {
    .rec{
        &__tableHeight {
            height:440px;
            padding: 7px;
        }
    }
    
    .removeResponsiveInMobile {
        .table-responsive{
            overflow: hidden;
        }
    }
    .recTableUI {
        thead {
            display: none;
            }
            tbody{
                display: block;margin-top: 15px;
                tr {
                    display: flex;width: 100%;position: relative;height: 70px;margin-bottom: 10px;justify-content: space-between;
                    &:after {content: "";position: absolute;left: 0;right: 0;top: -12px;border-bottom: 1px solid #dee2e6;}
                    td{
                        width: 25%;font-size:16px;position: relative;top: 20px;border: 0;left: 0;padding: 8px 10px 0 0;overflow: hidden;text-overflow: ellipsis;
                        &:first-child {
                            width: calc(100% - 60px);position: absolute;top: 0;left: 0;padding: 5px 0 0 0;overflow: visible;
                            .tableText {
                                width: auto;
                                padding-left: 60px;
                                a {
                                    margin: 0 !important;
                                    //width: calc(100vw - 150px);
                                    width: 99%;
                                }
                                img {
                                    position: absolute;
                                    left: 0;
                                    width: 55px;
                                    height: 55px;
                                    top: 0;
                                    z-index: 1;
                                }
                            }
                        }
                        &:nth-child(2) {
                            margin-left: 60px;
                            width:50px;
                        }
                        &:nth-child(5) {
                            width: 55px;
                            position: absolute;
                            right: 0;
                            top: -9px;
                            left: auto;
                            padding-right: 0;
                            text-align: right;
                            color: #000;
                            font-size: 20px;
                        }
                        &:last-child {
                            width: 28px;
                            text-align: right;
                            padding-right: 0;
                        }
                    }
                }
            }
    }


.rec {
        &__header {
            height:auto;
        }
        &__search {
            right: unset;
            width: 100% !important;
            top: 50px;
            align-items: center;
        }
        &__userinfo {
            padding: 0;
        }
        &__navlabel {
            padding: 0;
            text-align: left;
        }
        &__mobileAlign {
            position: absolute;
            top: -24px;
            left: 95px;
        }
        &__profileImg {
            width:90px;
            margin-top: -10px;
            margin-bottom: -10px;
        }
        &__infoText {
            font-size: 12px;
            margin-bottom: 15px;
        }
        &__nav {
            flex-wrap: wrap;
            margin-top: 5px;
            margin-bottom: 10px;
        }
        &__hbtn {
            text-align: left;
            margin-right: 0;
            font-size: 16px !important;
            padding: 0px 5px;
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
        }
        &__bgBase {
            flex-direction: column-reverse;
        }
        &__filter {
            margin-bottom: 5px;
            display:none;
        }
        &__holder {
            height: 100%;
            overflow-y: inherit;
            margin-bottom: 30px;
            min-height: calc(100vh - 590px);
            padding-right: 5px !important;
            padding-left: 5px !important;
        }
        &__footer {
            text-align: center;
            position: static !important;
            margin-top: 20px;
        }
        &__displayText {
            font-size: 25px !important;
            line-height: inherit !important;
        }
        &__loaderSection {
            width:100%;
            img {
               width:100%; 
            }
        }
        .__filterWrapper{
            display:flex;
        }
        .filterActive {
            .filterBg {
                background: #fff;
            }
        }
        .showFilter {
            display:block;
            position: fixed;
            width: 100%;
            height: 100vh;
            left: 0;
            top: 115px;
            z-index: 1;
        }
        ._recInput {
            height: auto !important;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        .__serachIcon {
            height: 30px;
        }
        .searchDropdown {
            position: fixed !important;
            width: 100% !important;
            left: 0;
            max-height: 100vh;
            height: 100vh;
            top:115px;
            z-index:2;
        }
        .searchActive{
            ._recInput {
                border-radius: 15px !important
            }
        }
        .mtext_left {
            text-align: left !important;
            border-bottom: 1px solid #e6e6e6;
            color: var(--primaryBlue);
        } 
        .custom_check {
            .form-check {
                text-align: right;
                .form-check-label {
                        position: relative;
        display: inline-block;
        padding-left: 25px;
        flex-direction: column;
        right: 10px;
        &::before {
            right: -25px;
            left: unset;  
                    }
                    &::after {
                        right: -23px;
                        left: unset;  
                    }
                }
            }
        }
        &__header {
            text-align: left;
            .__hLogo {
                margin-top:15px;
            }
        }
    }
    .c-modal {
        overflow: hidden;
    }
    .stopScroll {
        overflow: hidden;
    }
}


@media only screen and (max-width: 575.98px) {
    .recTableUI {
            tbody{
                tr {
                    td{
                        width: 28%;
                    }
                }
            }
        }
}
@media only screen and (max-width: 520px) {
    .recTableUI {
            tbody{
                tr {
                    td{
                        width: 125px;
                    }
                }
            }
        }
}
@media only screen and (max-width: 414px) { //iphone6/7/8/plus & pixel2 - XL
    .recTableUI {
            tbody{
                tr {
                    td{
                        width: 95px;
                        padding-right: 3px;
                    }
                }
            }
        }
        .rec {
            &__infoText {
                width: 79% !important;
            }
            .hElipses {
                width: 99%;
            }
        }
}
@media only screen and (max-width: 375px) { //iphoneX
    .recTableUI {
            tbody{
                tr {
                    td{
                        width: 90px;
                    }
                }
            }
        }
}
@media only screen and (max-width: 360px) { //GalaxyS5
    .recTableUI {
            tbody{
                tr {
                    td{
                        width: 65px;
                    }
                }
            }
        }
}


.__movie {
   color: var(--secondaryPink) !important;
}
.__video {
    color: #454343 !important;
 }
 .__channel {
    color: #ff8100 !important;
 }
.fCenter {
    display:flex;
    align-items:center;
    justify-content: center;
}

.watchnxt-enter {
  height:372px;
}
.watchnxt-enter-active {
  opacity: 1;
  transition: height 200ms;
}
.watchnxt-exit {
    opacity: 0;
  height:max-content;
}
.watchnxt-exit-active {
    opacity: 0;
  transition: height 200ms;
}

.elipsesText
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}
.titleBold
{
    font-weight: 400;
    letter-spacing: 0.2px;
}
.RecommendationsComponent{
    .btn-link.focus, .btn-link:focus {
        text-decoration: unset;
        box-shadow: unset;
    }
}
/*custom checkbox ----*/
.custom_check input[type="checkbox"] {opacity: 0;}
.custom_check label {position: relative;display: inline-block;padding-left: 25px;}
.custom_check label::before,.custom_check label::after {position: absolute;content: "";display: inline-block;}
.custom_check label::before{height: 15px;width: 15px;border: 1px solid #838383;border-radius: 3px;left: 0px;top: 6px;}
.custom_check label::after {height: 5px;width: 10px;border-left: 2px solid;border-bottom: 2px solid;transform: rotate(-45deg);left: 3px;top: 10px;}
.custom_check input[type="checkbox"] + label::after {content: none;}
.custom_check input[type="checkbox"]:checked + label::after {content: "";color:#838383}
.custom_check input[type="checkbox"]:focus + label::before {outline: rgb(252, 252, 252) auto 5px;}
.custom_check input[type="checkbox"]:checked + label::before {background-color: #fff;border:1px solid #838383;}
.custom_check  .form-check > * {cursor: pointer;}