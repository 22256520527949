.fullHeight {
    height: calc(100vh - 90px);
    position: relative;
}

.quoteLoader {
    position: relative;
    width: 800px;
    margin: auto;
    text-align: right;
    padding: 140px 0 70px;
}

.quoteLoader .text {
    font-size: 2.75em;
    font-style: italic;
    text-align: center;
    line-height: normal;
    margin-bottom: 10px;
    color: var(--primaryBlue);
}

.text:after, .text:before {
    content: '"'; 
}

.quoteLoader .name {
    font-weight: bold;
    font-size: 1.1em;
    color: #595858;
}

.quoteLoader:after,.quoteLoader:before {
    position: absolute;
    content: "";
    height: 34px;
    width: 244px;
    right: 0;
    left: 0;
    margin: auto;
    background-image: url('../../assets/images/zigzag.gif');
}
.quoteLoader:after {top:70px;}
.quoteLoader:before {bottom: 30px;}
.quoteLoader .name:before {content: "-";padding-right: 10px;}


@media only screen and (max-width: 991.98px) {
    .fullHeight {
        min-height: calc(100vh - 159px);
        height: auto;
    }
    .quoteLoader {
        position: relative;
        width: 80%;
        margin: auto;
        text-align: right;
        padding: 120px 0 70px;
    }
    .quoteLoader .text {
        font-size: 2em;
    }

    .quoteLoader .name {
       font-size: .9em;
    }
}